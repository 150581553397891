<template>
    <div class="mehr btm-menu">
        <Breadcrumb title="Weitere Inhalte" bgImage="/assets/bg/login.jpg"/>

        <ul class="mehr-menu">

            <li class="mehr-menu-link seprator">
                <div class="mehr-menu-link__icon">
                    <img src="/assets/logo/tripmeister_schwarz_klein.png" class="" alt="Tripmeister-Logo">
                </div>
                <router-link class="mehr-menu-link__text" style="text-transform: none" :to="{ name: 'Trips'}">entdECKungen</router-link>                
                <span class="material-icons ml-auto">navigate_next</span>
            </li>

            <li v-if="userLoggedIn" class="mehr-menu-link">
              <div class="mehr-menu-link__icon">
                  <span class="material-icons-outlined">person</span>
              </div>
              <router-link class="mehr-menu-link__text" :to="{ name: 'Profile'}">Mein Profil</router-link>   
              <span class="material-icons ml-auto">navigate_next</span>

          </li>
          <li class="mehr-menu-link">
            <div class="mehr-menu-link__icon">
                <span class="material-icons-outlined">feedback</span>
            </div>
            <a style="cursor: pointer" href="/" class= "mehr-menu-link__text" @click.prevent="showFeedbackModal">Feedback</a>               
            <span class="material-icons ml-auto">navigate_next</span>
        </li>
        <li v-if="userLoggedIn" class="mehr-menu-link seprator">
            <div class="mehr-menu-link__icon">
                <span class="material-icons-outlined">lock</span>
            </div>
            <a style="cursor: pointer" href="/" class= "mehr-menu-link__text" @click.prevent="logOut">Logout</a>               
            <span class="material-icons ml-auto">navigate_next</span>
        </li>



        <li class="mehr-menu-link seprator" style="display:none;">
            <div class="mehr-menu-link__icon">
                <span class="material-icons-outlined">search</span>
            </div>
            <router-link class="mehr-menu-link__text" :to="{ name: 'search'}">Suche</router-link>                
            <span class="material-icons ml-auto">navigate_next</span>
        </li>
        <li class="mehr-menu-link" style="display:none;">
            <div class="mehr-menu-link__icon">
                <span class="material-icons-outlined">description</span>
            </div>
            <router-link class="mehr-menu-link__text" :to="{ name: 'AGB'}">AGB</router-link>
            <span class="material-icons ml-auto">navigate_next</span>
        </li>
        <li class="mehr-menu-link">
            <div class="mehr-menu-link__icon">
                <span class="material-icons-outlined">description</span>
            </div>
            <router-link class="mehr-menu-link__text" :to="{ name: 'Nutzungsbedingungen'}">Nutzungsbedingungen</router-link>
            <span class="material-icons ml-auto">navigate_next</span>
        </li>
        <li class="mehr-menu-link">
            <div class="mehr-menu-link__icon">
                <span class="material-icons-outlined">shield</span>
            </div>
            <router-link class="mehr-menu-link__text" :to="{ name: 'Datenschutz'}">Datenschutzerklärung</router-link>
            <span class="material-icons ml-auto">navigate_next</span>
        </li>
        <li class="mehr-menu-link">
            <div class="mehr-menu-link__icon">
                <span class="material-icons-outlined">policy</span>
            </div>
            <router-link class="mehr-menu-link__text" :to="{ name: 'Impressum'}">Impressum</router-link>
            <span class="material-icons ml-auto">navigate_next</span>
        </li>
        <li class="mehr-menu-link">
            <div class="mehr-menu-link__icon">
                <span class="material-icons-outlined">lock</span>
            </div>
            <a class="mehr-menu-link__text" href="https://cms.tripmeister.de/#/login" target="_blank">Login für Anbietende</a>                
            <span class="material-icons ml-auto">navigate_next</span>
        </li>
        <li class="mehr-menu-link">
            <div class="mehr-menu-link__icon">
                <img class="software-by-design-icon" :src="'/assets/logo/sbd.png'" alt="software-by-design">
            </div>

            <a href="https://www.software-by-design.de/" class="mehr-menu-link__text" target="_blank">Software by Design</a>
            <span class="material-icons ml-auto">navigate_next</span>
        </li>
    </ul>

    <div v-if="!userLoggedIn" class="container-fluid login-container">
      <router-link :to="{ name: 'Login'}" class="btn btn-block to-upper login-btn">
        <span class="material-icons-outlined inline-icon">person</span>
        <span class="ml-2">LOGIN</span></router-link>
    </div>

</div>
</template>

<script>
  import { showLoader, hideLoader } from '@/utils/helpers';
  import { bus } from '@/main';

  export default {
    name: 'Mehr',
    components: {
      Breadcrumb: () => import('@/components/layout/Breadcrumb'),
  },
  computed: {
      userLoggedIn() {
        if(this.$store.getters.getToken){
          return true;
      }
      return false;
  },
  currentRoute(){
    return this.$route.name;
}
},
methods: {
  showLoader,
  hideLoader,
  showFeedbackModal() {
    bus.$emit('show-feedback-modal', true);
},
logOut(){
    this.loader = this.showLoader(this.loader);
    this.$store.dispatch('logout');

    this.$notify({
      duration: 3500,
      title: 'Erfolgreich abgemeldet!',
      text: 'Sie haben sich vom Tripmeister abgemeldet.'
  });

    this.loader = this.hideLoader(this.loader);
    this.$router.push({name:'Home'});
},
},
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .to-upper{
        text-transform:uppercase;
    }

    .software-by-design-icon{
        width:24px;
        height:24px;
    }

    .mehr {
        background-color: $white-color;
        height: 100vh;
        display: flex;
        flex-direction: column;

        &-menu {
            display: flex;
            flex-direction: column;

            &-link {
                display: flex;
                flex-direction: row;
                height: 50px;
                align-items: center;
                padding: 0 15px;

                &.seprator {
                    border-bottom: 1px solid rgba($color: $dark-color, $alpha: 0.2);
                }

                &__icon{
                    width: 40px;
                    display: inline-flex;
                    align-items: center;
                }

                &__text {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 14px;
                    color: $dark-color;
                }
            }
        }
    }

    .login-container {
        margin-top: 50px;

        @media(max-height: 630px){
            margin-top: 20px;
        }

        @media(max-height: 590px){
            margin-top: 5px;
        }

        .login-btn {
            align-items: center;
            justify-content: center;
            display: flex;
        }
    }
</style>
